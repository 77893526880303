import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material-nextjs@5.16.6_@emotion+cache@11.13.1_@mui+material@5.16.6_@emotion+react@11.13._h34qtxt6qesuq7pnzpyrszse5e/node_modules/@mui/material-nextjs/v13-appRouter/appRouterV13.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/AppBar/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/@mui+material@5.16.6_@emotion+react@11.13.0_@types+react@18.3.3_react@18.3.1__@emotion+styled_ziejykpst75yaj5seqyg2b5i2y/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/.pnpm/@vercel+analytics@1.3.1_next@14.2.5_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18_tl75h3tssux2ehkh5lbcnpgfpi/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/.pnpm/@vercel+speed-insights@1.0.12_next@14.2.5_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-_cg3klfooc2nqrigtbxoo3lufhy/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.5_@babel+core@7.24.5_babel-plugin-macros@3.1.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/styles/GlobalStyles.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/styles/mobileFix.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/Header/MainNavigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/MainContainer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/NextLink.tsx");
